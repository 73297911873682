import React from 'react';
import { Box, Flex, Text, Link as RebassLink } from 'rebass/styled-components';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Logo from './Logo';

const StyledFooter = styled(Flex)`
  background-color: #000;
  min-height: 300px;
  color: #eee;
`;

const Internal = styled(Link)`
  color: #eee;
  text-decoration: none;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 300;

  &:hover {
    color: #f6b91a;
    transition: all 200ms ease-in-out;
  }
`;

const ExternalStyled = styled(RebassLink)`
  color: #eee;
  text-decoration: none;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 300;

  &:hover {
    color: #f6b91a;
    transition: all 200ms ease-in-out;
  }
`;

const External = styled(RebassLink)`
  color: #eee;
  text-decoration: none;
  margin-bottom: 15px;

  &:hover {
    color: #f6b91a;
    transition: all 200ms ease-in-out;
  }
`;

const Footer = () => {
  return (
    <StyledFooter flex={1} p={4} alignItems="center" flexDirection="column">
      <Flex
        flex={1}
        mb={3}
        width={1}
        flexDirection={['column', 'column', 'row']}
      >
        <Flex
          width={[1, 1, 1 / 4]}
          flexDirection="column"
          alignItems="flex-start"
          pr={[0, 0, 6]}
          mb={[4, 4, 0]}
        >
          <Box mb={4}>
            <Logo dark />
          </Box>
          <Text mb={2}>Picture Motion Campaigns</Text>
          <Text lineHeight={1.7} fontWeight={300}>
            PO Box 696
          </Text>
          <Text lineHeight={1.7} fontWeight={300} mb={2}>
            New York, NY 10029
          </Text>
        </Flex>
        <Flex
          width={[1, 1, 1 / 4]}
          flexDirection="column"
          alignItems="flex-start"
          pr={[0, 0, 6]}
          mb={[4, 4, 0]}
        >
          <Internal to="/about">About</Internal>
          <Internal to="/work">Work</Internal>
          <Internal to="/services">Services</Internal>
          <Internal to="/team">Team</Internal>
          <Internal to="/press">Press</Internal>
          <Internal to="/blog">Blog</Internal>
          <ExternalStyled
            href="https://boards.greenhouse.io/picturemotion"
            target="_blank"
          >
            Jobs
          </ExternalStyled>
        </Flex>
        <Flex
          width={[1, 1, 1 / 4]}
          flexDirection="column"
          alignItems="flex-start"
          pr={[0, 0, 6]}
          mb={[4, 4, 0]}
        >
          <Text mb={2}>Contact Us</Text>
          <Text mb={3} fontWeight={300}>
            <External href="mailto:hello@picturemotion.com">
              hello@picturemotion.com
            </External>
          </Text>
          <External
            mt={1}
            href="https://twitter.com/picture_motion"
            fontSize={2}
          >
            Twitter
          </External>
          <External href="https://www.instagram.com/teampicmo/">
            Instagram
          </External>
          <External href="https://www.facebook.com/PictureMotionCampaigns">
            Facebook
          </External>
        </Flex>
        <Flex
          flex={1}
          width={[1, 1, 1 / 4]}
          justifyContent={['', '', 'flex-end']}
        >
          <Box width={1}>
            <Text mb={4} fontWeight={500} fontSize={3}>
              Join the Picmo Community
            </Text>
            <Box
              mb={4}
              sx={{
                a: {
                  color: '#f6b91a',
                  textDecoration: 'none',
                  fontSize: 18,
                  fontWeight: 500,
                  border: '1px solid #f6b91a',
                  borderRadius: 5,
                  py: 2,
                  px: 3,
                },
              }}
            >
              <a href="https://picturemotion.us7.list-manage.com/subscribe?u=a45587d6d85770726ade748ce&id=d88bd5ac49">
                Sign Up &rarr;
              </a>
            </Box>
          </Box>
        </Flex>
      </Flex>
      <Flex width={1} css={{ borderTop: '1px solid #333' }} pt={3}>
        <Flex flex={1}>
          <Text color="#555">
            &copy; {new Date().getFullYear()} Picture Motion
          </Text>
        </Flex>
        <Flex>
          <Text
            color="#999"
            sx={{ a: { color: '#999', textDecoration: 'none' } }}
          >
            <Link to="/privacy">Privacy Policy</Link>
          </Text>
        </Flex>
        <Flex />
      </Flex>
    </StyledFooter>
  );
};

export default Footer;
