import React from 'react';
import { Link } from 'gatsby';

import { Flex, FlexProps } from 'rebass/styled-components';

import Logo from './Logo';
import Menu from './Menu';

export interface HeaderProps {
  dark?: boolean;
}

const Header: React.FC<HeaderProps & FlexProps> = ({ dark, ...props }) => {
  return (
    <Flex {...props}>
      <Flex p={3}>
        <Link to="/">
          <Logo dark={dark} />
        </Link>
      </Flex>
      <Flex p={3} justifyContent="flex-end" alignItems="center" flex={1}>
        <Menu dark={dark} />
      </Flex>
    </Flex>
  );
};

export default Header;
