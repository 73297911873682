import React from 'react';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';

const Container = styled(Flex)`
  max-width: 1024px;
`;

const CenterContainer = props => {
  return (
    <Flex justifyContent="center">
      <Container {...props}>
        {props.children}
      </Container>
    </Flex>
  )
}

export default CenterContainer;