import React from 'react';

import { Heading, Text } from 'rebass/styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import CenterContainer from '../components/CenterContainer';
import Header from '../components/Header';
import Footer from '../components/Footer';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Header px={[0, 0, 3]} />
    <CenterContainer
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      css={{ minHeight: '60vh' }}
    >
      <Heading fontSize={6} mb={4}>
        NOT FOUND
      </Heading>
      <Text fontSize={4} textAlign="center">
        You just hit a page that doesn&#39;t exist... the sadness.
      </Text>
    </CenterContainer>
    <Footer />
  </Layout>
);

export default NotFoundPage;
