import { Link as GatsbyLink } from 'gatsby';
import React, { Fragment, useState } from 'react';
import { Box, Button, Flex, FlexProps } from 'rebass/styled-components';
import styled from 'styled-components';

const ShowSmall = styled(Box)`
  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const ShowLarge = styled(Flex)`
  @media screen and (max-width: 52em) {
    display: none;
  }
`;

const Link = styled(Box)<{ dark: boolean }>`
  font-weight: 200;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  color: ${props => (props.dark ? '#fff' : '#000')};
  line-height: 1;

  &:hover:after {
    opacity: 1;
  }

  a {
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 16px;
    color: ${props => (props.dark ? '#fff' : '#000')};
    line-height: 1;
  }

  &:after {
    content: '';
    width: calc(100% + 8px);
    height: 2px;
    margin-left: -4px;
    display: block;
    background-color: #f6b91a;
    opacity: 0;
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
    -webkit-transition: opacity 300ms ease-in-out,
      -webkit-transform 300ms ease-in-out;
    transition: opacity 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out,
      -webkit-transform 300ms ease-in-out;
  }
`;

const MobileLink = styled(GatsbyLink)`
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 28px;
  color: #000;
`;

const MobileMenu = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  height: 100vh;
  justify-content: center;
  opacity: ${props => props.opacity};
`;

const MenuClose = styled(Button)`
  position: absolute;
  right: 15px;
  top: 15px;
  background: transparent;
  outline: none;
  border: none;
  font-size: 35px;
  font-weight: 500;
  color: #000;
`;

const MenuItem: React.FC<any> = ({ dark, ...props }) => (
  <Flex ml={5}>
    <Link dark={dark}>
      <GatsbyLink to={props.to}>{props.title}</GatsbyLink>
    </Link>
  </Flex>
);

const MobileMenuItem: React.FC<any> = props => (
  <Flex my={4} px={3}>
    <MobileLink to={props.to}>{props.title}</MobileLink>
  </Flex>
);

const Hamburger = ({ color = 'black', size = 30 }) => (
  <svg height={size} width={size} viewBox="0 0 32 32">
    <path
      fill={color}
      d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"
    />
  </svg>
);

const Menu: React.FC<{ dark?: boolean }> = ({ dark }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <ShowSmall onClick={toggleOpen}>
        <Hamburger />
      </ShowSmall>
      <ShowLarge>
        <MenuItem dark={dark} title="about" to="/about" />
        <MenuItem dark={dark} title="Work" to="/work" />
				<MenuItem dark={dark} title="Services" to="/services" />
        <MenuItem dark={dark} title="Team" to="/team" />
        <MenuItem dark={dark} title="Press" to="/press" />
        <MenuItem dark={dark} title="Blog" to="/blog" />
      </ShowLarge>
      {open && (
        <MobileMenu flex={1} width={1} flexDirection="column" bg="#F5B93C">
          <MenuClose onClick={toggleOpen}>X</MenuClose>
          <MobileMenuItem title="About" to="/about" />
          <MobileMenuItem title="Work" to="/work" />
					<MobileMenuItem title="Services" to="/services" />
          <MobileMenuItem title="Team" to="/team" />
          <MobileMenuItem title="Press" to="/press" />
          <MobileMenuItem title="Blog" to="/blog" />
        </MobileMenu>
      )}
    </Fragment>
  );
};

export default Menu;
